import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, StaticQuery, graphql } from "gatsby"

import ShapeBlog from "../components/shapes/ShapeBlog"

const Referencje = ({ referencesAll }) => {
  return (
    <Layout>
      <SEO title="Referencje" />

      <main className="references-page">
        <ShapeBlog />

        <div className="container-fluid">
          <div className="page_header_wrapper">
            <h1 className="page_header" data-text="&#60;Referencje/&#62;">
              &#60;Referencje/&#62;
            </h1>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            {referencesAll.edges.map(({ node }) => (
              <div className="col-lg-4" key={node.id}>
                <Link
                  to={`/referencje/${node.slug}`}
                  className="references-page--tile"
                >
                  <img
                    className="img-fluid"
                    src={node.acf.reference_logo.source_url}
                    alt={node.title}
                  />
                  <p>{node.acf.reference_excerpt_short.substring(0, 100)}...</p>
                  <span className="btn_custom">&#60;Więcej/&#62;</span>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        referencesAll: allWordpressWpReferences {
          edges {
            node {
              id
              title
              slug
              acf {
                reference_excerpt_short
                reference_logo {
                  source_url
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Referencje {...data} />}
  />
)
